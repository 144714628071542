module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-1cf0f99393/0/cache/gatsby-plugin-manifest-npm-5.12.0-7e9af7e841-4476d736ac.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"ko","start_url":"/","icon":"static/favicon.ico","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8583cb76e66f3c446e5086336605ea4b"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-9566928ba4/0/cache/gatsby-plugin-google-gtag-npm-5.12.0-6bb2cbf5b0-367b10b791.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P6ZQDMKWCT"],"gtagConfig":{"optimize_id":"G-P6ZQDMKWCT","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-58e6508aa7/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
